$(function(){

	// MOBILE MENU
	$(".mobile-menu__item:has(ul)")
		.find("a:first")
		.addClass('mobile-menu__link--parent')
		.append("<span class='open-sub'>"+
					"<i class='fa fa-chevron-right'></i>"+
				"</span>");

	$(".mobile-menu--sub").each(function() {
		var title = $(this).parent("li").find("a:first").text();
		$(this).prepend("<li class='mobile-menu__item mobile-menu__item--header'>"+
							"<a class='mobile-menu__link' href='#'>"+
								"<span><i class='fa fa-chevron-left'></i></span>" +
								title +
							"</a>" +
						"</li>");
	});

	$(".open-sub").each(function() {
		$(this).click(function(e) {
			$(this).closest(".mobile-menu").removeClass("show").addClass("close");
			$(this).closest(".mobile-menu__item").find(".mobile-menu").addClass("show");
			e.preventDefault();
		});
	});


	$(".mobile-menu").width($(".mobile__wrapper").width());

	$(".mobile-menu__item--header a").each(function() {
		$(this).click(function(e) {

			var parentmenu = $(this).closest(".mobile-menu");

			parentmenu.removeClass("show");
			parentmenu.closest(".mobile-menu__item").parent(".mobile-menu--sub").addClass("show");
			parentmenu.closest(".mobile-menu__item").parent(".mobile-menu").removeClass("close");
			e.preventDefault();
		});
	});

	$('.colorbox').colorbox({
		rel:'gallery',
		maxWidth:"80%",
		maxHeight:"80%"
	});

	$('.extranetmenu li:not([data-icon=""])').each(function() {
			var icon = $(this).attr('data-icon');
			$(this).first("a").prepend('<i class="fa fa-'+ icon +'"></i>');
		$(this).addClass("list--icon");
		});

	$('.menu--main .menu__link').each(function(){
		$(this).find('i.fa-chevron-down').appendTo($(this).find('a').first());
	});

	if ($('html.lt_ie9').length === 0) {
		$('.header').clone().addClass('sticky').prependTo('body');
	}


	$(window).on('scroll', function(e){
		clearTimeout(t);
		var t = setTimeout(function(){

			if ($('body.vervolg').length > 0) {
				hHeight = 360;
			} else {
				hHeight = 480;
			}

			if ($(window).scrollTop() >= hHeight) {
				$('.header.sticky').addClass('visible');
			} else {
				$('.header.sticky').removeClass('visible');
			}
		}, 100);
	});

		// TOGGLE MOBILE MENU
	$(".menu__link--menu, .toggle-menu").click(function(e) {
		$("body").toggleClass("show-menu");
		$(this).toggleClass("active");
		e.preventDefault();
	});

	// $('.sidebar .titlelink').each(function(){
	// 	$(this).clone().addClass('clone cta cta--big').insertAfter('.header:not(.sticky)');
	// });

	// $('.titlelink.clone').wrapAll('<div class="cta__holder  cta__holder--mobile  clone"/>');
	// $('.titlelink.clone:eq(1)').addClass('cta--grey');
	// $('.titlelink.clone:eq(2)').addClass('cta--red');

	// cta_holder = $('.cta--big:first').closest('section');
	// cta_holder.addClass('cta__holder').clone().addClass('cta__holder  cta__holder--mobile  clone').insertAfter('.header:not(.sticky)');

	$('.content .project-contact').each(function(){
		$(this).prependTo('.sidebar').wrapAll('<section class="clearfix" />');
	});




	$('.menu__link--phone').on('click', function(){
		if ($(window).width() < 768) {
			document.location = "/contact";
		}
	});

	var owl = $(".partners__slider").owlCarousel({
		autoWidth: true,
		margin: 10,
		loop: true,
		items: 4,
		autoplay: true,
		autoplayTimeout: 6000,
		autoplayHoverPause: true
	});

	$('.menu__link--phone').on('click', function(){
		if ($(window).width() >= 768) {
			$(this).find('.menu--phone').toggleClass('open');
		}
	});

	$('.menu__link--menu, .menu--open').on('click', function(e){
		$('.menu--main').addClass('open');
		e.preventDefault();
	});

	$('.menu__close').on('click', function(e){
		$('.menu--main').removeClass('open');
		e.preventDefault();
	});

	$('.menu--phone li').on('click', function(e){
		// console.log($(this).find('a').attr('data-value'));
		$(this).closest('.menu__link--phone').find('> ul > li > span').text($(this).find('a').attr('data-value'));
		e.preventDefault();
	});

	$('.partners__slider__btn--next').click(function(e) {
		owl.trigger('next.owl.carousel');
		e.preventDefault();
	});

	$('.partners__slider__btn--prev').click(function(e) {
		owl.trigger('prev.owl.carousel');
		e.preventDefault();
	});

	//FILTER
	$(".filter .options li").click(function() {
		$(this).toggleClass("selected");

		var cRequest = $(this).closest(".filters").attr("data-value")

		var cRegioFilter = "";
		$(".filter .options li.selected[data-regio]").each(function() {
			cRegioFilter += (cRegioFilter === "" ? "" : ",") + $(this).attr("data-regio");
		});

		var cVakgebiedFilter = "";
		$(".filter .options li.selected[data-vakgebied]").each(function() {
			cVakgebiedFilter += (cVakgebiedFilter === "" ? "" : ",") + $(this).attr("data-vakgebied");
		});

		var cExpertiseFilter = "";
		$(".filter .options li.selected[data-expertise]").each(function() {
			cExpertiseFilter += (cExpertiseFilter === "" ? "" : ",") + $(this).attr("data-expertise");
		});

		cRequest = (cRegioFilter === "" ? cRequest : cRequest + "&regio=" + cRegioFilter.split(" ").join(""));
		cRequest = (cVakgebiedFilter === "" ? cRequest : cRequest + "&vakgebied=" + cVakgebiedFilter.split(" ").join("").replace("&", ""));
		cRequest = (cExpertiseFilter === "" ? cRequest : cRequest + "&expertise=" + cExpertiseFilter.split(" ").join(""));

		$(this).closest(".filters").next("ul.productlijst").load(cRequest);
		$(this).closest(".filters").next("ul.productoverzicht").load(cRequest);
		$(this).closest(".filters").parent().find(".paging").hide();
	});

	$(".filter .toggle").click(function() {
		$(this).closest(".filter").toggleClass("active");
	});
	$(".filter").mouseleave(function() {
		$(this).removeClass("active");
	});

	//BREADCRUMB
	$("div#breadcrumb ul li:last").addClass('last');

	// TABS IN FOOTER
	$(".tab_content").hide();
	$("ul.tabs li:first").addClass("active").show();
	$("ul.tabs li:last").addClass('last');
	$(".tab_content:first").show();

	$("ul.tabs li").click(function(e){
		$("ul.tabs li").removeClass("active");
		$(this).addClass("active");
		$(".tab_content").hide();

		var index = $("ul.tabs li").index($(this));
		$(".tab_container .tab_content:eq("+ index +")").show();

		e.preventDefault();
	});

	//VERWIJDER LEGE TAB CONTAINERS
	$("#tab1, #tab2, #tab3, .tabs").each( function() {
		if ($(this).children().length == 0) {
			$(this).remove();
		}
	});

	//TABELLEN
	$("table.standaard tr").each(function(){
		$(this).find("td:first").addClass("first");
	});
	$("table.rij").each(function(){
		$(this).find("tr:odd").addClass("odd");
	});
	$("table.kolom tr").each(function(){
		$(this).find("td:even").addClass("even");
	});

	//SEARCH
	/*
	$('.search a').clickToggle(function(){
		$('#search').slideDown(300);
		//$('body').animate({'background-position-y': '248'}, 0);
	}, function(){
		$('#search').slideUp(300);
		//$('body').animate({'background-position-y': '198'}, 0);
	});
	*/

	$('.search a').click(function(e){
		$('#search').slideToggle(300);
		e.preventDefault();
		});

	//DROPDOWNMENU
	$("#menu ul li").hover(function(){
		$(this).addClass("hover");
		$('ul:first',this).css('visibility', 'visible');
	}, function(){
		$(this).removeClass("hover");
		$('ul:first',this).css('visibility', 'hidden');
	});



	// REMOVE MARGIN BIJ OPEENVOLGENDE AFBEELDINGEN
	$("#content figure.left, #content figure.right, .kolom1 figure.left, .kolom1 figure.right, section figure.left, section figure.right").each(function() {
		if ($(this).next("figure.left, figure.right").length >= 1) {
			if (!$(this).hasClass("noMargin")) {
				$(this).next().addClass("noMargin");
			}
		}
	});

	//CLEAR CALL TO ACTIONS
	$("a.btn").each(function(){
		$(this).before("<div class=\"clear\"></div>");
		$(this).after("<div class=\"clear\"></div>");
	});

	$('[placeholder]').placeholder();

	// LOGO
	$(".samenwerken").delay(5000).fadeIn(5000);
	$(".ingenieus").delay(1500).animate({ top: "70px"}, 2000 );
	$('.ingenieurs').delay(3500).fadeIn(2000).delay(6000).fadeOut(2000);

});

$(window).load(function(){

	//BREEDTE AFBEELDING = MAXIMALE BREEDTE VOOR ONDERSCHRIFT
	$("figure").each(function(){
		$(this).find("figcaption").show().css("max-width", $(this).find("img").width());
	});

	$(window).on("resize", function() {
		$('.content .container').css({ minHeight: $('.sidebar').outerHeight() });
	}).trigger("resize");

	$(".link--download").on("click", function(){
		try{
			gaDocName=$(this).text();
			gaDocName=(gaDocName==""?$(this).parent().text():gaDocName);
			gaDocName=(gaDocName==""?($(this).find('img').attr('alt')):gaDocName);
			console.log(gaDocName);
			ga('send', 'event', 'Bestand gedownload', 'download', gaDocName);
		}catch(e){console.log(e);}
	});

});
